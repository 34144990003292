<template>
  <v-container fluid :style="cssProps">
    <v-card class="custom-card pa-2" dark elevation="0">
      <div class="card-corner">
        <div class="transparent-triangle"></div>
        <div class="colored-triangle"></div>
      </div>
      <v-card-title class="card-title"
        >{{ pricingPlanType | PricingPlanFormatter }}
        <span class="tax-year">{{ tier }}</span></v-card-title
      >
      <v-card-subtitle class="card-price">
        <span v-if="getRealPrice != getPrice" class="euro-cancelled pr-1">{{
          $options.filters.UnhideCurrencyFormatter(
            getPrice,
            pricingPlanCurrency
          )
        }}</span>
        <span class="euro">{{
          getRealPrice == getPrice
            ? $options.filters.UnhideCurrencyFormatter(
                getPrice,
                pricingPlanCurrency
              )
            : $options.filters.UnhideCurrencyFormatter(
                getRealPrice,
                pricingPlanCurrency
              )
        }}</span>

        <span class="price-period">{{
          hasOlderPlans
            ? $t("plan.yearly")
            : $t("plan.allYears", { year: taxYear })
        }}</span>
      </v-card-subtitle>
      <v-card-text>
        <p class="features-title mb-3">{{ $t("plan.whatsIncluded") }}</p>
        <v-list dense class="card-list">
          <v-list-item v-for="(feature, i) in getFeatures" :key="i">
            <v-icon class="pb-4 card-list-icon" left>mdi-check-circle</v-icon>
            <p class="card-list-text" v-html="feature.feature"></p>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="isButtonDisabled"
          @click="submit"
          :loading="loading"
          block
          tile
          elevation="0"
          :color="getButtonColor"
          :dark="getButtonDark"
          class="pa-0 purchase-button"
        >
          <template>
            <span class="button-text">{{ getButtonText }}</span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
/* global fbq */
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
export default {
  name: "young-pricing-card",
  props: {
    pricingPlanType: {
      type: String,
      required: true,
    },
    tier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      taxYear: new Date().getFullYear() - 1,
      pricingPlanCurrency: "EUR",
    };
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapFields("tax", ["taxYearTransactions"]),
    cssProps() {
      switch (this.pricingPlanType) {
        case "YOUNG_PLATFORM":
          return {
            "--plan-background-color": "#262a2a",
            "--plan-text-color": "#ffffff",
            "--plan-accent-color": "#00D672",
            "--plan-button-color": "#00D672",
            "--plan-button-text-color": "#ffffff",
          };
        default:
          return {
            "--plan-background-color": "#4479fa",
            "--plan-text-color": "#ffffff",
            "--plan-accent-color": "#e3de00",
            "--plan-button-color": "#262a2a",
          };
      }
    },
    getButtonColor() {
      switch (this.pricingPlanType) {
        case "YOUNG_PLATFORM":
          return "#00D672";
        default:
          return "#4479fa";
      }
    },
    getButtonDark() {
      switch (this.pricingPlanType) {
        case "YOUNG_PLATFORM":
          return false;
        default:
          return true;
      }
    },
    getPrices() {
      if (this.hasOlderPlans) {
        return {
          BASE: 79,
          BRONZE: 59,
          SILVER: 54,
          GOLD: 49,
          PLATINUM: 44,
        };
      } else {
        return {
          BASE: 79,
          BRONZE: 59,
          SILVER: 54,
          GOLD: 49,
          PLATINUM: 44,
        };
      }
    },
    getTransactionLimits() {
      return {
        YOUNG_PLATFORM: 10000,
      };
    },
    hasOlderPlans() {
      let olderPlans = this.loggedUser.pricingPlan.taxYearsAvailable.filter(
        (x) => x.taxYear != this.taxYear
      );
      if (olderPlans.length == 0) {
        return false;
      } else {
        let oldestPurchaseYear = Math.min(
          ...olderPlans.map((x) => new Date(x.purchaseDate).getFullYear())
        );
        console.log(oldestPurchaseYear);
        // Check if the oldest purchase year is older than current year
        if (oldestPurchaseYear < new Date().getFullYear()) {
          return true;
        } else {
          return false;
        }
      }
    },
    isCurrentPlan() {
      let plan = this.loggedUser.pricingPlan.taxYearsAvailable.find(
        (x) => x.taxYear == this.taxYear
      );
      if (plan != null) {
        return this.pricingPlanType == plan.pricingPlanType;
      } else {
        if (this.pricingPlanType == "FREE") {
          return true;
        } else {
          return false;
        }
      }
    },
    getCurrentPlan() {
      let plan = this.loggedUser.pricingPlan.taxYearsAvailable.find(
        (x) => x.taxYear == this.taxYear
      );
      if (plan != null) {
        return plan.pricingPlanType;
      } else {
        return "FREE";
      }
    },
    isButtonDisabled() {
      return (
        this.isCurrentPlan ||
        !this.isPlanAvailable ||
        this.getTransactionLimits[this.getCurrentPlan] >=
          this.getTransactionLimits[this.pricingPlanType]
      );
    },
    getPrice() {
      if (this.pricingPlanType == "FREE") {
        return 0;
      } else {
        return this.getPrices[this.tier];
      }
    },
    getRealPrice() {
      if (
        this.getCurrentPlan != "FREE" &&
        this.getTransactionLimits[this.getCurrentPlan] <
          this.getTransactionLimits[this.pricingPlanType]
      ) {
        return this.getPrices[this.tier] - this.getPrices[this.getCurrentPlan];
      } else {
        return this.getPrices[this.tier];
      }
    },
    getPricingPlanPeriodType() {
      return "LIFETIME";
    },
    getButtonText() {
      if (this.isCurrentPlan) {
        return this.$t("plan.currentPlanButton");
      }
      if (
        this.getCurrentPlan != "FREE" &&
        this.getTransactionLimits[this.getCurrentPlan] <
          this.getTransactionLimits[this.pricingPlanType]
      ) {
        return this.$t("plan.upgradeButton");
      } else {
        if (this.isPlanAvailable) {
          return this.$t("plan.buyButton");
        } else {
          return this.$t("plan.notAvailableButton");
        }
      }
    },
    isPlanAvailable() {
      if (
        this.getCurrentPlan != "FREE" &&
        this.getTransactionLimits[this.getCurrentPlan] >=
          this.getTransactionLimits[this.pricingPlanType]
      ) {
        return false;
      }
      return (
        this.taxYearTransactions <=
        this.getTransactionLimits[this.pricingPlanType]
      );
    },
    getFeatures() {
      if (this.hasOlderPlans) {
        return [
          { feature: this.$t("plan.feature.connections"), active: true },
          {
            feature: this.$t("plan.feature.youngReports", { numTx: 10000 }),
            active: true,
          },
          { feature: this.$t("plan.feature.premiumSupport"), active: true },
        ];
      } else {
        return [
          { feature: this.$t("plan.feature.connections"), active: true },
          {
            feature: this.$t("plan.feature.youngReports", { numTx: 10000 }),
            active: true,
          },
          { feature: this.$t("plan.feature.youngAllYears"), active: true },
          { feature: this.$t("plan.feature.premiumSupport"), active: true },
        ];
      }
    },
  },
  methods: {
    ...mapActions("payment", ["createCheckoutSession", "createPortalSession"]),
    submit() {
      this.loading = true;
      // You will be redirected to Stripe's secure checkout page
      const payload = {
        paymentProviderType: "STRIPE",
        pricingPlanPeriodType: this.getPricingPlanPeriodType,
        pricingPlanType: this.pricingPlanType,
      };
      this.createCheckoutSession(payload)
        .then((response) => {
          // Send the 'begin_checkout' event to GA4
          this.$gtag.event("begin_checkout", {
            currency: "EUR", // Set your currency
            value: this.getPrice, // Replace with actual product price
            items: [
              {
                item_name: this.pricingPlanType + "_" + this.tier, // Replace with product name
                item_id: this.pricingPlanType + "_" + this.tier,
                price: this.getPrice,
                quantity: 1,
              },
            ],
          });
          if (typeof fbq !== "undefined") {
            fbq("track", "InitiateCheckout", );
          }
          window.location.href = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.card-list {
  background-color: transparent;
  background-size: 20px 20px;
}
.card-list-text {
  color: var(--plan-text-color);
}
.card-list-icon {
  color: var(--plan-accent-color);
}
.custom-card {
  position: relative;
  border-radius: 0px;
  background-color: var(--plan-background-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Default shadow */
}

.custom-card:hover {
  transform: translateY(-10px); /* Slightly lift the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow for hover effect */
}
.button-text {
  color: var(--plan-button-text-color);
}
.purchase-button {
  transition: background-color 0.3s ease; /* Smooth transition */
}

.purchase-button:hover {
  background-color: lighten(
    var(--plan-button-color),
    10%
  ); /* Make the button lighter */
  filter: brightness(1.2); /* Optional: Increase brightness slightly */
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--plan-button-color) !important;
  opacity: 0.75;
}
/* Left-Side Dots Pattern */
.custom-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80%; /* Restrict the dots to the left 30% of the card */
  height: 80%;
  background: transparent;
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.1) 2px,
    transparent 2px
  ); /* Dots pattern */
  background-size: 20px 20px; /* Size and spacing of dots */
  background-color: transparent; /* Same as the card background */
  z-index: 1; /* Ensure it appears behind the content */
}
.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
  color: var(--plan-text-color); /* #ffffff */
}

.card-price .euro {
  color: var(--plan-text-color); /* #ffffff */
  font-size: 3rem;
  font-weight: bold;
}

.card-price .euro-cancelled {
  color: var(--plan-text-color); /* #ffffff */
  font-size: 1.5rem;
  font-weight: normal;
  text-decoration: line-through;
}

.card-price {
  font-size: 2.2rem;
  margin-top: 0;
}

.price-period {
  font-size: 1rem;
  color: var(--plan-accent-color); /*#e3de00;*/
  margin-left: 0.2rem;
}

/* Features Title */
.features-title {
  font-size: 1rem;
  font-weight: bold;
  color: var(--plan-accent-color);
  margin-bottom: 0;
  text-transform: uppercase;
}
.price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align to top-right */
  margin-right: 0.5rem;
}

.real-price {
  font-size: 2.5rem; /* Adjust as needed */
  font-weight: bold;
  color: var(--plan-text-color);
}

.canceled-price {
  font-size: 1.5rem; /* Adjust as needed */
  text-decoration: line-through;
  font-weight: normal;
  color: var(--plan-accent-color); /* A lighter color for canceled price */
}
.card-corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

.transparent-triangle {
  border-left: 50px solid transparent;
  border-right: 50px solid var(--v-background-base);
  border-bottom: 50px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 0px;
  z-index: 2;
}

.colored-triangle {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 0 50px;
  border-color: rgba(255, 255, 255, 0.5);
}

.button-transparent-triangle {
  border-left: 10px solid transparent;
  border-right: 10px solid var(--plan-background-color);
  border-bottom: 10px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 0px;
  z-index: 2;
}

.button-colored-triangle {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: rgba(255, 255, 255, 0.5);
}
.tax-year {
  margin-left: 5px;
  color: var(--plan-accent-color);
  font-weight: normal;
}
.button-corner {
  position: absolute;
  top: -10px;
  right: 0;
  width: 10px;
  height: 10px;
}
</style>